<template>
  <section class="encyclopedia_britannica">
    <div class="header_content">
      <img class="title" src="~assets/images/anti-counterfeiting-title.png">
      <img class="button" src="~assets/images/anti-counterfeiting-button.png" @click="inspectionAuthenticity">
    </div>
    <div class="content">
      <img src="~assets/images/anti-counterfeiting-content.png">
    </div>
  </section>
</template>

<script>
  import axios from 'axios';

  export default {
    created() {
      let headHTML = document.getElementsByTagName('head')[0].innerHTML;
      headHTML += '<link rel="icon" href="">';
      document.getElementsByTagName('head')[0].innerHTML = headHTML;
      this.datas.jump_wxa.query = 'query=' + encodeURIComponent(this.$route.fullPath.split('?')[1] || '');
    },

    data() {
      return {
        datas: {
          loginSource: 'CYAN_GOURD',
          jump_wxa: {
            env_version: '',
            path: '/pagesA/scan/scan',
            query: ''
          }
        },
        locationURL: ''
      }
    },

    methods: {
      inspectionAuthenticity() {
        if(this.locationURL) {
          location.href = this.locationURL;
          return;
        }
        axios({
          url: 'https://fd.hollo-edu.com/hullo-cloud/client/login/getURLScheme',
          method: 'post',
          data: JSON.stringify(this.datas),
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        }).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.locationURL = res.data.data;
          location.href = this.locationURL;
        });
      }
    }
  }
</script>

<style lang="less" scoped>
  .encyclopedia_britannica {
    width: 100vw;
    height: 100vh;
    padding-top: 300px;
    background: url('~assets/images/anti-counterfeiting-background.png') no-repeat;
    background-size: 100% 100%;
    .header_content {
      position: relative;
      .title {
        width: 1920px;
        height: 1218px;
      }
      .button {
        position: absolute;
        top: 600px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        width: 1507px;
        height: 343px;
      }
    }
    .content {
      margin: 0 auto;
      width: 1751px;
      height: 1697px;
    }
  }
</style>
